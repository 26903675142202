<template>
  <div>
    <header class="container__header">
      <div class="header__logo">
        <img src="@/assets/images/logo.png" alt="Boituvet" />
      </div>
      <div class="header__titles">
        <ul class="header__titles-ul">
          <li><router-link to="/adm/exames">Exames</router-link></li>
        </ul>
        <a @click="logout" id="logout"><img src="@/assets/images/logout.svg" alt="Logout"></a>
      </div>
    </header>
    <div class="container__table"
      v-if="relatorios && relatorios.length > 0"
    >
      <div class="container__table-header">
        <h1>Gerenciamento Financeiro</h1>
      </div>
      <div class="container__table-all">
        <div class="container__table-cards"
          v-for="(relatorio, i) in relatorios"
          :key="i"
        >
          <div class="container__month">
            <span id="month">{{ meses[relatorio.MES - 1] }}</span>
          </div>
          <div class="container__table-value">
            <div class="container__values-titles">
              <p>Quantidade:</p>
              <p>Custos:</p>
              <p>Faturamento:</p>
              <p>Lucro:</p>
            </div>
            <div class="container__values-prices">
              <p id="qtdd-exames">{{ relatorio.QTDD_EXAMES }} exames</p>
              <p id="custo">R${{ Number(relatorio.CUSTO_TOTAL).toFixed(2) }}</p>
              <p id="faturamento">R${{ Number(relatorio.FATURAMENTO).toFixed(2) }}</p>
              <p id="lucro">R${{ Number(relatorio.LUCRO_LIQUIDO).toFixed(2) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container__table"
      v-else-if="relatorios && relatorios.length <= 0"
    >
    <div class="container__table-header">
      <h1>Não há solicitações</h1>
    </div>
    </div>
    <BaseLoading class="center"
      v-else
    />
  </div>
</template>

<script>
import BaseLoading from '@/components/BaseLoading.vue'
import axios from 'axios'

export default {
  name: 'DashboardAdmView',
  components: {
    BaseLoading
  },
  data () {
    return {
      user: {},
      relatorios: null,
      meses: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
    }
  },
  methods: {
    logout () {
      axios.get('https://labbvet.boituvet.com.br/private/api/PUT/logout.php', {
        withCredentials: true
      })
        .then(() => {
          window.localStorage.clear()
          location.reload()
        })
    },
    getRelatorios () {
      axios.get('https://labbvet.boituvet.com.br/private/api/GET/relatorios.php', {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          console.log(response)
          this.relatorios = response.data
        })
        .catch((_) => {
          console.log(_)
        })
    }
  },
  mounted () {
    this.getRelatorios()
  }
}
</script>

<style scoped>
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(95vh - 60px);
  margin: 5vh 0 0 0;
}

/* Header */
.container__header {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-color);
  width: 100%;
  height: 60px;
  padding: 0 30px;
  box-shadow: 0 0 15px #00000070;
}

.header__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.header__logo img {
  width: 90px;
  height: 40px;
}

.header__titles {
  width: 35%;
  display: flex;
  align-items: center;
}

#logout {
  margin-left: auto;
}
.header__titles-ul {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
}

.header__titles-ul li {
  list-style: none;
  font-size: 20px;
}

.header__titles-ul :first-child {
  margin-right: 5px;
}

.header__titles-ul li a {
  text-decoration: none;
  color: #000;
  transition: 0.5s;
}

.header__titles-ul li a:hover {
  transition: 0.5s;
  color: var(--primary-color);
}

/* table */

.container__table {
  margin: 100px auto 0 auto;
  max-width: 70%;
  max-height: 500px;

  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);

  padding-bottom: 20px;
}

.container__table-header {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container__table-header h1 {
  font-size: 20px;
  color: var(--primary-color);
}

.container__table-all {
  width: 90%;
  max-height: 350px;
  overflow-y: auto;
  padding-right: 10px;
  margin: 0 auto;
}

.container__table-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 150px;
  padding: 25px 50px;
  background-color: var(--primary-color);
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: white;
}

.container__table-value {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 50%;
}

.container__values-titles :first-child,
.container__values-prices :first-child {
  margin-bottom: 10px;
}

.container__month span {
  font-size: 25px;
  font-weight: 700;
}

@media (max-width:808px) {
  .container__table-header {
    text-align: center;
  }
  .container__table-cards {
    font-size: 15px;
    height: 200px;
    display: block;
  }

  .container__month {
    text-align: center;
    padding-top: 10px;
    height: 50px;
  }

  .container__month span {
    font-size: 20px;
  }

  .container__table-value {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .container__values-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .container__values-arrow img {
    transform: rotate(90deg);
  }

  .container__table {
    margin-top: 50px;
  }

}
</style>
