<template>
  <div>
    <header class="container__header">
      <div class="header__logo">
        <img src="@/assets/images/logo.png" alt="Boituvet" />
      </div>
      <div class="header__titles">
        <router-link to="/adm"><img id="voltar" src="@/assets/images/voltar.svg" alt="Voltar"></router-link>
      </div>
    </header>
    <div class="container__principal">
      <!--Confirmação-->
      <ModalConfirmacao
        v-if="showModalConfirmacaoExcluirCategoria"
        :text="'Tem certeza que deseja excluir a Categoria?'"
        @close="showModalConfirmacaoExcluirCategoria = false; idCategoria = null"
        @excluir="excluirCategoria()"
      />
      <ModalConfirmacao
        v-if="showModalConfirmacaoExcluirExame"
        :text="'Tem certeza que deseja excluir o Exame?'"
        @close="showModalConfirmacaoExcluirExame = false; idExame = null"
        @excluir="excluirExame()"
      />
      <!--Cadastro de Exame-->
      <ModalCadExame
        v-if="formCadExame.show"
        :exame="formCadExame.exame"
        :callback="formCadExame.callback"
        @cadastrar="cadastrarExame()"
        @close="formCadExame.show = false; formCadExame.callback = {}"
        @update:nome_tipo_exame="formCadExame.exame.NOME_TIPO_EXAME = $event"
        @update:amostra="formCadExame.exame.AMOSTRA = $event"
        @update:custo="formCadExame.exame.CUSTO = $event"
        @update:preco="formCadExame.exame.PRECO = $event"
        @update:prazo="formCadExame.exame.PRAZO = $event"
      />
      <!--Edição de exame-->
      <ModalEditarExame
        v-if="formEdicaoExame.show"
        :exame="exame"
        :callback="formEdicaoExame.callback"
        @editar="editarExame()"
        @close="formEdicaoExame.show = false; idExame = null; exame = {}"
        @update:nome_tipo_exame="exame.NOME_TIPO_EXAME = $event"
        @update:amostra="exame.AMOSTRA = $event"
        @update:custo="exame.CUSTO = $event"
        @update:preco="exame.PRECO = $event"
        @update:prazo="exame.PRAZO = $event"
      />
      <ModalEditarCategoria
        v-if="formEdicaoCategoria.show"
        :categoria="formEdicaoCategoria.categoria"
        :callback="formEdicaoCategoria.callback"
        @editar="editarCategoria()"
        @close="formEdicaoCategoria.show = false"
        @update:nome_categoria="formEdicaoCategoria.categoria.NOME_CATEGORIA = $event"
      />
      <!--Cadastrar Categoria-->
      <ModalCadCategoria
        v-if="formCadCategoria.show"
        :nomeCategoria="formCadCategoria.nomeCategoria"
        :callback="formCadCategoria.callback"
        @update:nomeCategoria="formCadCategoria.nomeCategoria = $event"
        @cadastrarCategoria="cadastrarCategoria()"
        @close="formCadCategoria.show = false; formCadCategoria.nomeCategoria = '';formCadCategoria.callback = {}"
      />
      <div class="container__header-exame">
        <h1 class="header__title">Alteração nas informações dos Exames</h1>
      </div>
      <div class="container_categoria_exame">
        <div class="container_categoria"
          v-if="categorias !== null"
        >
          <!-- Categorias  -->
          <div class="wrapper_categoria">
            <div class="header_title-iconAdd">
            <h1>Categorias</h1>
            <img id="add_categoria" class="add_btn" src="@/assets/images/Add.svg" alt="adicionar"
              @click="formCadCategoria.show = true"
            >
            </div>
            <div class="categoria"
              v-for="categoria in categorias"
              :key="categoria.ID_CATEGORIA"
              @click="attCurrentCategoria($event);getExames(categoria.ID_CATEGORIA)"
            >
              <p>{{ categoria.NOME_CATEGORIA }}</p>
              <img src="@/assets/images/Add.svg" class="btn_close-categoria categoria__p-icons" alt="close"
                @click="showModalConfirmacaoExcluirCategoria = true"
              >
              <img src="@/assets/images/pencilEdit.svg" class="btn_edit-categoria categoria__p-icons" alt="pencil"
                @click="formEdicaoCategoria.show = true; formEdicaoCategoria.categoria= categoria"
              >
            </div>
          </div>
        </div>
        <div class="container_categoria"
          v-else
        >
          <div class="wrapper_categoria">
            <h1>Categorias</h1>
            <BaseLoading/>
          </div>
        </div>
        <div class="container__exame">
          <div class="header__exame"
            v-if="currentCategoria"
          >
            <h1>Exames</h1>
            <img id="add_exame" class="add_btn-exame" src="@/assets/images/Add.svg" alt="Adicionar"
              @click="formCadExame.show = true"
            >
          </div>
          <!-- Exames -->
          <div class="container__exames_lista"
            v-if="exames !== null && exames.length > 0"
          >
            <div class="exame"
              v-for="exame in exames"
              :key="exame.ID_TIPO_EXAME"
            >
              <div class="exame-col-1">
                <span id="nome_exame">{{ exame.NOME_TIPO_EXAME }}</span>
                <p>Amostra: <a id="amostra">{{ exame.AMOSTRA }}</a> </p>
                <p>Prazo: <a id="prazo">{{ exame.PRAZO }} dias</a></p>
              </div>

              <div class="exame-col-2">
                <button id="excluir"
                  @click="showModalConfirmacaoExcluirExame = true; idExame = exame.ID_TIPO_EXAME"
                >
                  <img src="@/assets/images/trashIcon.svg" alt="Excluir"/>
                </button>
                <button id="editar"
                  @click="getExame(exame.ID_TIPO_EXAME); formEdicaoExame.show = true"
                >
                  <img src="@/assets/images/pencilEdit.svg" alt="Editar"/>
                </button>
              </div>
            </div>
          </div>
          <div class="container__exames_lista"
            v-else-if="exames !== null && exames.length <= 0"
          >
            <h1>Não há exames nessa categoria</h1>
          </div>
          <div class="container__exames_lista"
            v-else-if="exames"
          >
            <BaseLoading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import BaseLoading from '@/components/BaseLoading.vue'
import ModalConfirmacao from './components/ModalConfirmacao.vue'
import ModalEditarExame from './components/ModalEditarExame.vue'
import ModalCadExame from './components/ModalCadExame.vue'
import ModalCadCategoria from './components/ModalCadCategoria.vue'
import ModalEditarCategoria from './components/ModalEditarCategoria.vue'

export default {
  name: 'ExamesView',
  components: {
    BaseLoading,
    ModalConfirmacao,
    ModalEditarExame,
    ModalCadExame,
    ModalCadCategoria,
    ModalEditarCategoria
  },
  data () {
    return {
      categorias: null,
      currentCategoria: null,
      currentCategoriaId: null,
      exames: false,
      exame: {},
      idExame: null,

      formCadCategoria: {
        nomeCategoria: null,
        callback: {},
        show: false
      },

      formCadExame: {
        exame: {
          NOME_TIPO_EXAME: null,
          AMOSTRA: null,
          CUSTO: null,
          PRECO: null,
          PRAZO: null
        },
        callback: {},
        show: false
      },

      formEdicaoExame: {
        callback: {},
        show: false
      },
      formEdicaoCategoria: {
        categoria: {},
        callback: {},
        show: false
      },
      showModalConfirmacaoExcluirExame: false,
      showModalConfirmacaoExcluirCategoria: false
    }
  },
  methods: {
    getCategorias () {
      axios.get('https://labbvet.boituvet.com.br/private/api/GET/categorias.php', {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.categorias = data
        })
        .catch((error) => {
          alert(error)
        })
    },
    getExames (id) {
      this.exames = null
      axios.get(`https://labbvet.boituvet.com.br/private/api/GET/examesEmCategoria.php?id_categoria=${id}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          this.currentCategoriaId = id
          this.exames = response.data
        })
        .catch((error) => {
          alert(error)
        })
    },
    getExame (id) {
      axios.get(`https://labbvet.boituvet.com.br/private/api/GET/exame.php?id_exame=${id}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.exame = data
        })
        .catch((error) => {
          alert(error)
        })
    },
    attCurrentCategoria (event) {
      if (this.currentCategoria) this.currentCategoria.classList.remove('current')
      event.target.classList.add('current')
      this.currentCategoria = event.target
    },
    excluirExame () {
      axios.get(`https://labbvet.boituvet.com.br/private/api/DELETE/exame.php?idExame=${this.idExame}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getExames(this.currentCategoriaId)
          }
          this.showModalConfirmacaoExcluirExame = false
        })
    },
    editarExame () {
      const formData = new FormData()
      for (const key in this.exame) formData.append(key, this.exame[key])
      axios.post('https://labbvet.boituvet.com.br/private/api/PUT/exame.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getExames(this.currentCategoriaId)
            this.formEdicaoExame.show = false
            this.exame = {}
            this.formEdicaoExame.callback = {}
          } else {
            this.formEdicaoExame.callback = callback
          }
        })
        .catch((_) => {
          console.log(_)
        })
    },
    cadastrarCategoria () {
      const formData = new FormData()
      formData.append('nomeCategoria', this.formCadCategoria.nomeCategoria)
      axios.post('https://labbvet.boituvet.com.br/private/api/POST/categoria.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getCategorias()
            this.formCadCategoria.show = false
            this.formCadCategoria.nomeCategoria = ''
            this.formCadCategoria.callback = {}
          } else {
            this.formCadCategoria.callback = callback
          }
        })
        .catch((_) => {
          console.log(_)
        })
    },
    cadastrarExame () {
      const formData = new FormData()
      for (const key in this.formCadExame.exame) formData.append(key, this.formCadExame.exame[key])
      formData.append('CATEGORIA', this.currentCategoriaId)
      axios.post('https://labbvet.boituvet.com.br/private/api/POST/exame.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getExames(this.currentCategoriaId)
            this.formCadExame.show = false
            this.formCadExame.exame = {
              NOME_TIPO_EXAME: null,
              AMOSTRA: null,
              CUSTO: null,
              PRECO: null,
              PRAZO: null
            }
            this.formCadExame.callback = {}
          } else {
            this.formCadExame.callback = callback
          }
        })
        .catch((_) => {
          console.log(_)
        })
    },
    excluirCategoria () {
      axios.get(`https://labbvet.boituvet.com.br/private/api/DELETE/categoria.php?idCategoria=${this.currentCategoriaId}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then(() => {
          this.currentCategoria = null
          this.currentCategoriaId = null
          this.showModalConfirmacaoExcluirCategoria = false
          this.getCategorias()
        })
        .catch((_) => {
          console.log(_)
        })
    },
    editarCategoria () {
      const formData = new FormData()
      formData.append('idCategoria', this.formEdicaoCategoria.categoria.ID_CATEGORIA)
      formData.append('nomeCategoria', this.formEdicaoCategoria.categoria.NOME_CATEGORIA)
      axios.post('https://labbvet.boituvet.com.br/private/api/PUT/categoria.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getExames(this.currentCategoriaId)
            this.formEdicaoCategoria.show = false
            this.formEdicaoCategoria.callback = {}
          } else {
            this.formEdicaoCategoria.callback = callback
          }
        })
        .catch((_) => {
          console.log(_)
        })
    }
  },
  mounted () {
    this.getCategorias()
  }
}
</script>

<style scoped>
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(90vh - 60px);
  min-height: 750px;
  margin: 5vh 0 0 0;
}

/* Header */
.container__header {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-color);
  width: 100%;
  height: 60px;
  box-shadow: 0 0 15px #00000070;
}

.header__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.header__logo img{
  width: 90px;
  height: 40px;
  margin: 30px;
}

.header__titles {
  width: 50%;
  display: flex;
  justify-content:flex-end;
  align-items: center;
}

.header__titles #voltar{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor:  pointer;
}

/* tabela */
.container__principal{
  margin: 50px auto;
  max-height: 500px;
  width: 80%;
}

/* Container__header */

.container__header-exame{
  height: 40px;
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 10px;
  box-shadow: 0px 9px 15px -10px black;
}

.container__header-exame h1{
  font-size: 20px;
  color: var(--light-color);
  text-align: center;
  padding: 5px;
}

/* Categorias e exames */

.container_categoria_exame{
  display: flex;
  width: 100%;
  height: 460px;
  margin-top: 10px;
}

/* Categorias */

.container_categoria{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  background-color: var(--primary-color);
  overflow-y: auto;
  border-radius: 10px;
  padding: 10px;
}

.wrapper_categoria{
  width: 80%;
  height: 100%;
  overflow-y:auto;
}
.container_categoria h1{
  color: var(--light-color);
  font-size: 25px;
}

.categoria{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  text-align: center;
  max-width: 90%;
  min-height: 60px;
  background-color: var(--light-color);
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 9px 15px -4px black;
  margin-bottom: 20px;
  color: var(--primary-light-color);
  cursor: pointer;
  word-break: break-all;
}
.categoria p{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  pointer-events:none;
}

.categoria img{
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.categoria .btn_close-categoria{
  filter: invert(12%) sepia(87%) saturate(7217%) hue-rotate(3deg) brightness(94%) contrast(117%);
}

.categoria .btn_edit-categoria{
  filter: invert(29%) sepia(14%) saturate(2006%) hue-rotate(185deg) brightness(95%) contrast(105%);
}
.categoria:hover p .categoria__p-icons{
  visibility: visible;
  overflow: auto;
}

.categoria.current {
  background-color: var(--primary-color);
  color: var(--light-color);
  border: 2px solid var(--light-color);
}

/* container__exames */

.container__exame{
  width: 70%;
  height: 100%;
  border: 1px solid var(--primary-light-color);
  margin-left: 20px;
  border-radius: 10px;
}

.header__exame{
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  padding-left: 20px;
  margin-bottom: 20px;
}

.header__exame h1{
  color: var(--primary-color);
  font-size: 25px;
  margin: 10px;
}

.add_btn{
  width: 30px;
  height: 30px;
  margin: 10px 10px 0;
  transform: rotate(45deg);
  cursor: pointer;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(303deg) brightness(200%) contrast(102%);
}
.add_btn-exame{
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 10px 10px 0 0;
  transform: rotate(45deg);
  filter: invert(20%) sepia(65%) saturate(1491%) hue-rotate(203deg) brightness(88%) contrast(81%);
}

.header_title-iconAdd{
  width: 100%;
  height: 80px;
  margin-top: 10px;
  text-align: center;

}
.container__exames_lista{
  width: 100%;
  height: 85%;
  overflow-y:auto;
  margin: 0 auto;
}

.container__exames_lista h1{
  margin-top: 20px;
  text-align: center;
  color: var(--primary-light-color);
  font-size: 25px;
}

.exame{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 25%;
}

.exame-col-1{
  height: 100%;
  max-width: 85%;
  word-break: break-all;
  padding: 20px;
}

.exame-col-1 #nome_exame{
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}

.exame-col-1 p{
  font-size: 15px;
  color: rgb(56, 56, 56);
  font-weight: 600;
}

.exame-col-1 a{
  opacity: 0.5;
}

.exame-col-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding-right: 10px;
}

.header__exame img{
  fill: var(--primary-color);
}

.exame-col-2 img:first-child{
  margin-bottom: 5px;
}

button{
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: none;
  margin-bottom: 5px;
}

#excluir{
  background-color: red;
}

#excluir img, #editar img{
  padding-top: 5px;
  width: 24px;
  height: 24px;
}

#editar{
  background-color: var(--primary-color);
}

@media (max-width: 770px){
  .container_categoria_exame{
    display: block;
  }

  .container_categoria{
    width: 100%;
    height: 50%;
  }
  .header_title-iconAdd{
    width: 100%;
    height: 50px;
    margin: 0 10px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container_categoria h1{
    display: flex;
    width: 150px;
  }

  .wrapper_categoria{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .categoria{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 20%;
    min-height: 80px;
    margin: 10px;
    box-shadow: 0px 9px 15px -10px black;
    font-size: 13px;
  }
  .categoria p{
    flex-direction: column;
  }

  .container__exame{
    width: 100%;
    height: 55%;
    margin-top: 20px;
    margin-left: 0;
  }

  .container__exames_lista{
    height: 70%;
    padding: 20px;
  }

  .container__principal{
    margin-top: 50px;
  }

  .exame{
    min-height: 40%;
    margin-bottom: 20px;
  }
  .exame-col-1 {
    padding: 10px;
  }

  .exame-col-2 img:first-child{
    margin-top: 5px;
  }

  #excluir img, #editar img{
    padding-top: 0;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 600px){
    .container__header-exame h1{
      font-size: 15px;
    }

    .wrapper_categoria{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .categoria{
    width: 30%;
  }
}

@media (max-width: 350px){
  .categoria{
    width: 40%;
    height: 50px;
    font-size: 10px;
  }
}

</style>
