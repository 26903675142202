<template>
  <div class="wrapper__modal">
    <div class="container__modal_info-solicitacao ">
      <div class="container__modal-header">
        <h1 id="paciente">{{ solicitacao.PACIENTE }}</h1>
        <img src="@/assets/images/closeIcon.svg" alt="X" @click="$emit('close')">
      </div>
      <div class="container__modal-informacao">
        <div class="container__dt_coleta container__info">
          <p>Paciente</p>
          <span>{{ solicitacao.PACIENTE }}</span>
        </div>
        <div class="container__dt_coleta container__info">
          <p>Especie</p>
          <span>{{ solicitacao.ESPECIE_ANIMAL }}</span>
        </div>
        <div class="container__dt_coleta container__info">
          <p>Raça</p>
          <span>{{ solicitacao.RACA }}</span>
        </div>
        <div class="container__dt_coleta container__info">
          <p>Idade Paciente</p>
          <span>{{ solicitacao.IDADE_ANIMAL }} {{ solicitacao.TIPO_IDADE_ANIMAL.toLowerCase() }}{{ (solicitacao.IDADE_ANIMAL > 1 && solicitacao.TIPO_IDADE_ANIMAL === 'ANO') ? 's' : ''}}{{ (solicitacao.IDADE_ANIMAL > 1 && solicitacao.TIPO_IDADE_ANIMAL === 'MES') ? 'es' : ''}}</span>
        </div>
        <div class="container__dt_coleta container__info">
          <p>Gênero</p>
          <span>{{ solicitacao.GENERO_ANIMAL }}</span>
        </div>
        <div class="container__tutor container__info">
          <p>Tutor</p>
          <span>{{ solicitacao.NOME_TUTOR }}</span>
        </div>
        <div class="container__dt_coleta container__info">
          <p>Data de coleta</p>
          <span>{{ solicitacao.DT_COLETA }}</span>
        </div>
        <div class="container__exame container__info">
          <p>Exames</p>
          <div class="wrapper_exames">
            <ul
              v-for="(exame, i) in examesEmSolicitacao"
              :key="i"
            >
              <li id="wrapper_exames-nomeTipoExame">
                {{  exame.NOME_TIPO_EXAME }}
              </li>
              <li>
                {{  exame.PRAZO }} dia{{ (exame.PRAZO > 1) ? 's' : '' }}
              </li>
              <li>
                {{  exame.PRECO}}
              </li>
              <li id="wrapper_exames-amostra">
                {{  exame.AMOSTRA }}
              </li>
            </ul>
            <br>
          </div>
        </div>
        <div class="container__veterinario container__info">
          <p>Veterinario</p>
          <span>{{ solicitacao.NOME_VETERINARIO }}</span>
        </div>
        <div class="container__CRMV container__info">
          <p>CRMV</p>
          <span>{{ solicitacao.CRMV_VETERINARIO }}</span>
        </div>
        <div class="container__observacao container__info">
          <p>Observação</p>
          <span> {{ solicitacao.OBSERVACAO }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoading from '@/components/BaseLoading.vue'

export default {
  name: 'ModalSolicitacao',
  props: ['solicitacao', 'examesEmSolicitacao'],
  components: [
    BaseLoading
  ]
}
</script>

<style scoped>
.wrapper__modal {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background: #00000015;
  backdrop-filter: blur(5px);
}

.container__modal_info-solicitacao {
  width: 40%;
  max-height: 500px;
  margin: 0 auto;
  background-color: var(--light-color);
  border-radius: 10px;
  box-shadow: 0px 4px 29px -8px rgba(0, 0, 0, 0.75);
  padding: 10px;
  overflow-y: auto;
}

.container__modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px 0 0 10px;
}

.container__modal-header h1 {
  font-size: 25px;
  font-weight: 800;
  color: var(--primary-color);
}

.container__modal-header img {
  width: 30px;
  height: 30px;
  margin: 0 5px 0 5px;
  filter: invert(20%) sepia(72%) saturate(784%) hue-rotate(191deg)
    brightness(100%) contrast(87%);
  cursor: pointer;
}

/* informação */

.container__modal-informacao {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-height: calc(100% - 80px);
  margin-top: 20px;
  font-size: 13px;
}

.container__info {
  display: block;
  width: 80%;
  height: 50px;
  height: fit-content;
}

.container__info p {
  color: var(--primary-light-color);
  font-weight: 700;
  margin-bottom: 5px;
}

.container__info span {
  max-width: 100%;
  min-height: 40px;
  overflow-wrap: break-word;
}

.container__info:last-child {
  margin-bottom: 20px;
}

.wrapper_exames ul li{
  list-style-type: none;
  margin-bottom: 5px;
}

.container__trash{
  display:flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  /* background: blue; */
}
.container__trash-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color:  #f13131;
  border: none;
  cursor: pointer;
}

#wrapper_exames-nomeTipoExame{
  font-weight: 700;
}

#wrapper_exames-amostra{
  padding-bottom: 10px;
}

.container__trash-icon:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

/* Container Informação das Solicitações */
@media all and (max-width: 850px) {
.container__modal_info-solicitacao {
    max-width: 50%;
    min-height: 400px;
    height: fit-content;
  }

  .container__modal-header h1 {
    font-size: 30px;
  }
}
</style>
