<template>
  <div>
    <!--Modal excluir Solicitação-->
    <ModalExcluirSolicitacoes
      v-if="formExcluirSolicitacoes.show"
      :dataInicio="formExcluirSolicitacoes.dataInicio"
      :dataFim="formExcluirSolicitacoes.dataFim"
      :callback="formExcluirSolicitacoes.callback"
      @excluirSolicitacoes="excluirSolicitacoes()"
      @close="formExcluirSolicitacoes.show = false"
      @update:dataInicio="formExcluirSolicitacoes.dataInicio = $event"
      @update:dataFim="formExcluirSolicitacoes.dataFim = $event"
    />

    <!--Modal Filtros Solicitação-->
    <ModalFiltrosSolicitacoes
      v-if="formFiltrosSolicitacoes.show"
      :limit="limit"
      @addFiltros="getSolicitacoes(); formFiltrosSolicitacoes.show = false"
      @update:limit="limit = $event; salvarFiltros(); "
      @close="formFiltrosSolicitacoes.show = false"
    />

    <!--Modal Solicitação-->
    <ModalSolicitacao
      v-if="showModalSolicitacao && examesEmSolicitacao && solicitacao"
      :solicitacao="solicitacao"
      :examesEmSolicitacao="examesEmSolicitacao"
      @close="showModalSolicitacao = false"
      @excluirSolicitacao="excluirSolicitacao($value)"
    />
    <BaseLoading class="center"
      v-else-if="showModalSolicitacao && (examesEmSolicitacao || solicitacao)"
    />
    <header class="container__header">
      <div class="header__logo">
        <img src="@/assets/images/logo.png" alt="Boituvet" />
      </div>
      <div class="header__titles">
        <ul class="header__titles-ul">
          <li><router-link to="/vet/cadastrar-solicitacao">Solicitações</router-link></li>
          <li @click="formFiltrosSolicitacoes.show = true">Filtros</li>
          <li @click="formExcluirSolicitacoes.show = true"><img id="apagar" src="@/assets/images/trashIcon.svg" alt="Apagar"/></li>
          <li @click="logout" id="logout"><img src="@/assets/images/logout.svg" alt="Logout"></li>
        </ul>
      </div>
    </header>
    <main>
      <!-- Table Solicitações -->
      <TabelaSolicitacoes
        :solicitacoes="solicitacoes"
        @update:solicitacao="solicitacao = solicitacoes[$event]; getExamesEmSolicitacao()"
        @abrir="showModalSolicitacao = true"
        v-if="solicitacoes"
      />
      <BaseLoading class="center"
      v-else
      />
      <!-- Noficações -->
      <BaseNotificacao/>
    </main>
  </div>
</template>

<script>
import TabelaSolicitacoes from './components/TabelaSolicitacoes.vue'
import ModalSolicitacao from './components/ModalSolicitacao.vue'
import BaseLoading from '@/components/BaseLoading.vue'
import ModalFiltrosSolicitacoes from '@/components/FiltrosModal.vue'
import BaseNotificacao from '@/components/BaseNotificacao.vue'
import ModalExcluirSolicitacoes from './components/ModalExcluirSolicitacao.vue'
import axios from 'axios'

export default {
  name: 'DashboardVetView',
  components: {
    TabelaSolicitacoes,
    ModalSolicitacao,
    BaseLoading,
    BaseNotificacao,
    ModalExcluirSolicitacoes,
    ModalFiltrosSolicitacoes
  },
  data () {
    return {
      limit: window.localStorage.getItem('limit') ?? 20,
      solicitacoes: null,
      solicitacao: null,
      examesEmSolicitacao: null,
      showModalSolicitacao: false,
      formExcluirSolicitacoes: {
        dataFim: null,
        dataInicio: null,
        show: false,
        callback: {}
      },
      formFiltrosSolicitacoes: {
        show: false
      }
    }
  },
  mounted () {
    this.getSolicitacoes()
    setInterval(() => {
      this.getSolicitacoes()
    }, 5000)
  },
  methods: {
    salvarFiltros () {
      if (isNaN(this.limit) || this.limit < 0) {
        this.limit = 0
      } else if (this.limit > 100) {
        this.limit = 100
      }
      window.localStorage.setItem('limit', this.limit)
    },
    logout () {
      axios
        .get('https://labbvet.boituvet.com.br/private/api/PUT/logout.php', {
          withCredentials: true
        })
        .then(() => {
          window.localStorage.clear()
          location.reload()
        })
        .catch((_) => {
          console.log(_)
        })
    },
    getSolicitacoes () {
      const params = (this.limit != null && this.limit > 0) ? `?limit=${this.limit}` : ''

      axios
        .get(`https://labbvet.boituvet.com.br/private/api/GET/solicitacoes.php${params}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.solicitacoes = data
        })
        .catch((_) => {
          console.log(_)
        })
    },
    getExamesEmSolicitacao () {
      this.examesEmSolicitacao = null
      axios
        .get(`https://labbvet.boituvet.com.br/private/api/GET/examesEmSolicitacao.php?id_solicitacao=${this.solicitacao.ID_SOLICITACAO}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.examesEmSolicitacao = data
        })
        .catch((_) => {
          console.log(_)
        })
    },
    excluirSolicitacoes () {
      const formData = new FormData()
      formData.append('dataInicio', this.formExcluirSolicitacoes.dataInicio)
      formData.append('dataFim', this.formExcluirSolicitacoes.dataFim)
      axios.post('https://labbvet.boituvet.com.br/private/api/DELETE/solicitacoes.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getSolicitacoes()
            this.formExcluirSolicitacoes.callback = {}
            this.formExcluirSolicitacoes.show = false
            this.formExcluirSolicitacoes.dataInicio = null
            this.formExcluirSolicitacoes.dataFim = null
          } else {
            this.formExcluirSolicitacoes.callback = callback
          }
        })
        .catch((_) => {
          console.log(_)
        })
    },
    excluirSolicitacao () {
      axios
        .get(`https://labbvet.boituvet.com.br/private/api/DELETE/solicitacao.php?idSolicitacao=${this.solicitacao.ID_SOLICITACAO}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          return response.data
        })
        .then((callback) => {
          if (callback.success) {
            this.getSolicitacoes()
            this.showModalSolicitacao = false
          }
        })
        .catch((_) => {
          console.log(_)
        })
    }
  }
}
</script>

<style scoped>
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(95vh - 60px);
  margin: 5vh 0 0 0;
}

/* Header */
.container__header {
  display: flex;
  justify-content: space-around;
  background-color: var(--light-color);
  width: 100%;
  height: 60px;
  box-shadow: 0 0 15px #000000e1;
}

.header__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.header__logo img {
  width: 90px;
  height: 40px;
}

.header__titles {
  width: 35%;
  display: flex;
  align-items: center;
}

#logout {
  margin-left: auto;
}
.header__titles-ul {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
}

.header__titles-ul li {
  list-style: none;
  font-size: 20px;
  cursor: pointer;
}

.header__titles-ul :first-child {
  margin-right: 20px;
}

.header__titles-ul li a {
  text-decoration: none;
  color: #000;
  transition: 0.5s;
}

.header__titles-ul li a:hover {
  transition: 0.5s;
  color: var(--primary-color);
}

#apagar {
  cursor: pointer;
  border-radius: 5px;
  margin-left: 32px;
  border:none;
  filter: invert(100%);
}

@media (max-width:650px){
  .header__titles-ul :first-child {
    margin-right: 10px;
  }

  .header__logo {
    display: none;
  }

  .header__titles-ul {
    justify-content: space-between;
  }

  .container__header {
    justify-content: center;
    align-items: center;
  }

  .header__titles-ul {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

}
</style>
