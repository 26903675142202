<template>
  <div class="wrapper_modal" >
    <div class="modal">
      <div class="modal_header">
        <div class="modal_header-span">
          <span>Editar categoria</span>
        </div>
      </div>
      <div class="modal_body">
        <div class="container_form">
          <label class="label" for="NOME_CATEGORIA">Nome do categoria:</label>
          <input type="text" :value="categoria.NOME_CATEGORIA" id="input__nome-categoria" name="NOME_CATEGORIA"
            @input="$emit('update:nome_categoria', $event.target.value)"
          >
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('nomeCategoria')">Campo vazio</p>
          </div>
        </div>
        <div class="container__form-btn">
          <button @click="$emit('editar')" class="form__btn bnt-confirmar">Confirmar</button>
          <button @click="$emit('close')" class="form__btn btn-cancelar" >Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEditarCategoria',
  props: ['categoria', 'callback']
}
</script>

<style scoped>
/* Modal edição */
.error {
  height: 16px;
  color: #e04545;
  font-weight: bold;
  margin: 10px 0;
}
.wrapper_modal {
  width: 100%;
  height: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #00000095;
  backdrop-filter: blur(5px);
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 350px;
  z-index: 9999;
  background-color: var(--light-color);
  border-radius: 10px;
  border: 1px solid var(--primary-color);
}

.modal_header{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

}
.modal_header-span{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--primary-light-color);
  text-transform: uppercase;
}

.modal_body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
  gap: 20px;
  /* background: blue; */
}

.modal_body p{
  font-weight: bold;
}
.container_form{
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 30%;
  margin: 20px 0 20px 0;
}

.container_form label,input{
  width: 100%;
  height: 20px;
}
.container_form label {
  margin-bottom: 20px;
}
.container__form-btn{
  min-height: 20%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.form__btn {
  width: 100px;
  height: 50px;
}

.bnt-confirmar:hover{
  box-shadow: 0 0 20px #3fd362, 0 0 5px #3fd362c0 inset;
}
.btn-cancelar{
  background-color: #ff0000;
}

.btn-cancelar:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

@media (max-width:778px){
    .modal {
      max-width: 70%;
    }
    .modal_body{
      margin-top: 20px;
    }

    .container__form-btn{
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

}
</style>
