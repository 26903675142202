<template>
  <div class="wrapper__table">
    <!-- Modal Confirmação -->
    <table class="container__table"
      v-if="solicitacoes.length  > 0"
    >
      <thead class="container__table-head">
        <tr>
          <th>#</th>
          <th>Paciente</th>
          <th>Tutor</th>
          <th>Data</th>
          <th>Status</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody class="container__table-body">
        <tr
          v-for="(solicitacao, i) in solicitacoes"
          :key="i"
        >
          <td data-label="#">{{ solicitacao.ID_SOLICITACAO }}</td>
          <td data-label="Paciente">{{ solicitacao.PACIENTE }}</td>
          <td data-label="Tutor">{{ solicitacao.NOME_TUTOR }}</td>
          <td data-label="Data">{{ solicitacao.DT_SOLICITACAO }}</td>
          <td data-label="Status" class="td-status">
            <p :class="`status ${solicitacao.STATUS_SOLICITACAO.split(' ').join('_')}`">{{ solicitacao.STATUS_SOLICITACAO }}</p>
          </td>
          <td data-label="Ação">
            <button @click="$emit('update:solicitacao', i); $emit('abrir')">
              <img src="@/assets/images/searchIcon.svg" alt="Lupa" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <h1
      v-else
    >
      Não há solicitações cadastrados
    </h1>
  </div>
</template>

<script>
export default {
  name: 'TabelaSolicitacoes',
  props: ['solicitacoes']
}
</script>

<style scoped>
h1 {
  text-align: center;
  color: var(--primary-color);
  width: 100%;
}
.wrapper__table{
  width: 420px;
  max-width: 70%;
  max-height: 100%;
  overflow-y: auto;
}

.container__btn-excluir{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.container__btn-excluir p{
  color: var(--primary-color);
  font-weight: bold;
}

.container__btn-excluir span{
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: red;
  border-radius: 5px;
  border:none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container__btn-excluir span:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

.container__btn-excluir span svg{
  width: 20px;
  height: 20px;
}

.container__btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  height: 70px;
}

.container__btn button{
  border:none;
  border-radius: 10px;
  width: 80px;
  height: 40px;
  color: white;
}

.btn_confirmar{
  background-color:#3fd362 ;
}
.btn_confirmar:hover{
  box-shadow: 0 0 10px #3fd362, 0 0 5px #3fd362c0 inset;
}
.btn_cancelar{
  background-color: #f13131;
}

.btn_cancelar:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

/* Acaba Aqui */

.container__table {
  width: 100%;
}

.container__table-head {
  display: none;
}

.container__table-body tr {
  border: 1px solid #dad6eb;
  border-radius: 5px;
  display: block;
  padding: 30px;
  margin-bottom: 30px;
}

.container__table-body tr td {
  display: block;
  padding: 5px;
  position: relative;
  text-align: right;
}

.container__table-body tr td button {
  background: var(--primary-color);
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(34, 34, 34, 0.2);
  bottom: -30px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  left: 50%;
  padding: 10px 0;
  position: absolute;
  transform: translate(-50%, 50%);
  transition: all 0.12s ease-out;
  width: 50%;
}

.container__table-body tr td button:hover,
.container__table-body tr td button:focus {
  background: var(--primary-light-color);
  cursor: pointer;
}

.container__table-body tr td button img {
  width: 20px;
  height: 20px;
}

.container__table-body tr td:before {
  color: #8a8a87;
  content: attr(data-label);
  font-weight: 400;
  left: 5px;
  position: absolute;
}

@media all and (max-width: 425px) {
  .header__titles-ul li a {
    font-size: 15px;
  }

  .container__table-body tr td {
    font-size: 10px;
  }

  .container__table-body tr td button {
    width: 50px;
    height: 35px;
  }
}

/* media query */
@media all and (min-width: 850px) {
  .wrapper__table {
    min-width: 850px;
  }

  .header__titles {
    justify-content: flex-end;
  }

  .container__table-head {
    display: table-header-group;
  }

  .container__table-head th {
    width: 250px;
    height: 40px;
    border-radius: 10px;
    text-align: center;
    color: var(--light-color);
    background: var(--primary-color);
  }

  .container__table-head th:last-child {
    width: 100px;
  }

  .container__table-body {
    font-size: 1em;
  }

  .container__table-body tr {
    display: table-row;
  }

  .container__table-body tr td {
    display: table-cell;
    padding: 10px;
    text-align: center;
  }

  .container__table-body tr td .status {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding-top: 5px;
    border-radius: 10px;
    font-weight: bold;
    color: var(--light-color);
    text-align: center;
  }
  .container__table-body .td-status p {
    text-align: center;
    font-size: 13px;
  }

  .status.FINALIZADO {
    background: #4efb4a;
  }

  .status.PROCESSANDO {
    background: #ffd359
  }
  .status.AGUARDO_DE_ANALISE {
    background: #fb914a;
  }

  .status.CANCELADO {
    background: #fb4a4a;
  }

  .container__table-body tr td button {
    display: inline-block;
    position: initial;
    transform: translate(0);
    width: 100px;
  }

  .container__table-body tr td:before {
    display: none;
  }
}
</style>
