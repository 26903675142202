<template>
  <div class="container">
    <ModalCadSolicitacao
      @close="toggle = false"
      :tipoModal="tipoModal"
      :mensagens="mensagens"
      v-if="toggle"
    />
    <!-- Menu Lateral -->
    <div class="container__menu">
      <router-link  to="/vet" class="container__menu-voltar">
        <img src="@/assets/images/voltar.svg" alt="voltar" />
      </router-link>
      <div class="container__menu-option">
        <div @click="currentIdForm = 0" class="menu__option option-animal current-option">
          <img src="@/assets/images/pacienteIcon.svg" alt="Animal" />
        </div>
        <div @click="currentIdForm = 1" class="menu__option option-exam">
          <img src="@/assets/images/exameIcon.svg" alt="Exame" />
        </div>
        <div @click="currentIdForm = 2" class="menu__option option-vet">
          <img src="@/assets/images/veterinarioIcon.svg" alt="Veterinario" />
        </div>
      </div>
    </div>

    <form @submit.prevent="submit">
      <FormCadSolicitacaoPaciente
        :dt_coleta="form.dt_coleta"
        :paciente="form.paciente"
        :raca="form.raca"
        :genero="form.genero"
        :idade="form.idade"
        :tipo_idade="form.tipo_idade"
        :especie="form.especie"
        :tutor="form.tutor"
        :proximoForm="proximoForm"
        @update:dt_coleta="form.dt_coleta = $event"
        @update:paciente="form.paciente = $event"
        @update:raca="form.raca = $event"
        @update:tipo_idade="form.tipo_idade = $event"
        @update:genero="form.genero = $event"
        @update:idade="form.idade = $event"
        @update:especie="form.especie = $event"
        @update:tutor="form.tutor = $event"
        v-if="currentIdForm === 0"
      />
      <FormCadSolicitacaoExame
        :categorias="categorias"
        :exames="exames"
        :exibirExames="getExames"
        :tipos_de_exame="form.tipos_de_exame"
        :addOuRemoveTipoExame="addOuRemoveTipoExame"
        :proximoForm="proximoForm"
        @update:exames="exames = $event"
        v-if="currentIdForm === 1"
      />
      <FormCadSolicitacaoVeterinario
        :crmv="form.crmv"
        @update:crmv="form.crmv = $event"
        @update:nome_veterinario="form.nome_veterinario = $event"
        @update:observacao="form.observacao = $event"
        :observacao="form.observacao"
        :nome_veterinario="form.nome_veterinario"
        :btnDesativado="btnDesativado"
        v-if="currentIdForm === 2"
      />
    </form>
  </div>
</template>

<script>
import FormCadSolicitacaoPaciente from './components/FormCadSolicitacaoPaciente.vue'
import FormCadSolicitacaoExame from './components/FormCadSolicitacaoExame.vue'
import FormCadSolicitacaoVeterinario from './components/FormCadSolicitacaoVeterinario.vue'
import ModalCadSolicitacao from './components/ModalCadSolicitacao.vue'
import axios from 'axios'

export default {
  name: 'CadSolicitacaoView',
  components: {
    FormCadSolicitacaoPaciente,
    FormCadSolicitacaoExame,
    FormCadSolicitacaoVeterinario,
    ModalCadSolicitacao
  },
  data () {
    return {
      categorias: null,
      currentCategoria: null,
      exames: true,
      currentIdForm: 0,
      toggle: false,
      form: {
        dt_coleta: null,
        paciente: null,
        raca: null,
        genero: null,
        idade: null,
        tipo_idade: null,
        especie: null,
        tutor: null,
        tipos_de_exame: [],
        nome_veterinario: null,
        crmv: null,
        observacao: '#'
      },
      tipoModal: 'error',
      mensagens: [],
      btnDesativado: false
    }
  },
  methods: {
    submit () {
      if (this.btnDesativado) {
        return false
      }
      this.btnDesativado = true
      const formData = new FormData()
      for (const key in this.form) formData.append(key, this.form[key])
      axios.post('https://labbvet.boituvet.com.br/private/api/POST/solicitacao.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          if (data.success) {
            this.tipoModal = 'success'
            this.mensagens = ['Exame cadastrado com sucesso!']
            this.form = {
              dt_coleta: null,
              paciente: null,
              genero: null,
              idade: null,
              tipo_idade: null,
              especie: null,
              tutor: null,
              tipos_de_exame: [],
              nome_veterinario: null,
              crmv: null,
              observacao: '#'
            }
          } else {
            this.tipoModal = 'error'
            this.mensagens = data.split(';')
          }
          this.toggle = true
          this.btnDesativado = false
        })
    },
    getCategorias () {
      axios.get('https://labbvet.boituvet.com.br/private/api/GET/categorias.php', {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.categorias = data
        })
        .catch((_) => {
          console.log(_)
        })
    },
    getExames (id) {
      this.exames = null
      axios.get(`https://labbvet.boituvet.com.br/private/api/GET/examesEmCategoria.php?id_categoria=${id}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          this.exames = response.data
        })
        .catch((_) => {
          console.log(_)
        })
    },
    proximoForm () {
      if (this.currentIdForm < 2) this.currentIdForm++
    },
    addOuRemoveTipoExame (id) {
      const indexTipoExame = this.form.tipos_de_exame.indexOf(id)
      if (indexTipoExame > -1) this.form.tipos_de_exame.splice(indexTipoExame, 1)
      else this.form.tipos_de_exame.push(id)
    }
  },
  mounted () {
    this.getCategorias()
  },
  watch: {
    currentIdForm (to) {
      const menuOptions = document.querySelectorAll('.menu__option')
      const currentOption = document.querySelectorAll('.current-option')
      currentOption.forEach((el) => {
        el.classList.remove('current-option')
      })
      menuOptions[to].classList.add('current-option')
    }
  }
}
</script>

<style>
.container {
  display: flex;
  width: 100%;
  height: 100vh;
  background: linear-gradient(181.91deg, #2F467F 49.13%, rgba(255, 255, 255, 0.77) 168.89%) !important;
}

button,
input[type="checkbox"] {
  cursor: pointer;
}

/* Menu Lateral */
.container__menu {
  width: 100px;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 15px #00000070;
  display: block;
  justify-content: center;
  align-items: center;
}

.container__menu-voltar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.container__menu-voltar img {
  width: 30px;
  height: 30px;
}

.container__menu-option{
  display: flex;
  flex-direction: column;
  margin-top: 50%;
  width: 100%;
  height: 400px;
}

.menu__option{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: .3s linear all;
}

.menu__option img{
  width: 90%;
  height: 50px;
}

.current-option,
.menu__option:hover {
  background-color: var(--primary-color);
}

.current-option img,
.menu__option:hover img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(90deg) brightness(90%) contrast(100%);
}

/* form */
form {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* min-height: 500px; */
}

.container__form{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  /* min-height: 500px; */
  padding-top: 20px;
}

label{
  color: var(--primary-color);
  font-weight: 500;
}

input{
  border: none;
  border-bottom: 1px solid var(--primary-color);
  outline: none;
  padding: 0 0 2px 10px;
}

.form__btn{
  width: 30%;
  max-width: 200px;
  height: 10%;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-color);
  font-weight: bolder;
  font-size: 1.05em;
  background: #02BF21;
  box-shadow: 0 0 15px #00000045;
  border: none;
  border-radius: 10px;
  margin-bottom: 25px;
}

@media (max-width: 840px) {
  .container {
    display: block
  }

  .container__menu {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .container__menu-option {
    height: 100%;
    margin: 0;
    flex-direction: row;
  }

  form {
    width: 100%;
    height: calc(100vh - 100px);
  }

  .form {
    width: 75%;
  }
}
</style>
