import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import LoginView from '@/views/LoginView.vue'
import DashboardVetView from '@/views/Vet/Dashboard/DashboardVet.vue'
import CadSolicitacaoView from '@/views/Vet/CadSolicitacao/CadSolicitacaoView.vue'
import DashboardLabView from '@/views/Lab/Dashboard/DashboardLab.vue'
import DashboardAdmView from '@/views/Adm/Dashboard/DashboardAdm.vue'
import ExamesView from '@/views/Adm/Exames/ExamesView.vue'
import axios from 'axios'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/vet',
    name: 'dashboardVet',
    component: DashboardVetView,
    meta: { requiresAuth: true, TIPO_USER: 'vet' }
  },
  {
    path: '/vet/cadastrar-solicitacao',
    name: 'cadSolicitacao',
    component: CadSolicitacaoView,
    meta: { requiresAuth: true, TIPO_USER: 'vet' }
  },
  {
    path: '/lab',
    name: 'dashboardLab',
    component: DashboardLabView,
    meta: { requiresAuth: true, TIPO_USER: 'lab' }
  },
  {
    path: '/adm',
    name: 'dashboardAdm',
    component: DashboardAdmView,
    meta: { requiresAuth: true, TIPO_USER: 'adm' }
  },
  {
    path: '/adm/exames',
    name: 'exames',
    component: ExamesView,
    meta: { requiresAuth: true, TIPO_USER: 'adm' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = window.localStorage.getItem('token')
    if (token) {
      await axios.get('https://labbvet.boituvet.com.br/private/api/GET/user.php', {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
      })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          if (data.TIPO_USER === to.meta.TIPO_USER && !data.error) {
            next()
          } else {
            window.localStorage.clear()
            next({ name: 'login' })
          }
        })
        .catch(() => {
          window.localStorage.clear()
          next({ name: 'login' })
        })
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

export default router
