<template>
  <div class="container__form">
    <div class="form">
      <div class="wrapper_form">
        <div class="container__title">
          <h1>Paciente:</h1>
        </div>
        <div class="container__inputs">
          <div class="container__form_col-1">
            <div class="container__data label__input">
              <label for="data">Data de coleta</label>
              <input type="date" name="DATA" id="input__data" :value="dt_coleta" @input="$emit('update:dt_coleta', $event.target.value)"/>
            </div>
            <div class="container__paciente label__input">
              <label for="paciente">Paciente:</label>
              <input type="text" name="PACIENTE" id="input__paciente" :value="paciente" @input="$emit('update:paciente', $event.target.value)"/>
            </div>
            <div class="container__raca label__input">
              <label for="raca">Raça:</label>
              <input type="text" name="RACA" id="input__raca" :value="raca" @input="$emit('update:raca', $event.target.value)"/>
            </div>
            <div class="container__genero">
              <p>Gênero:</p>
              <input type="radio" id="input__macho" name="GENERO" value="M" v-bind:checked="genero === 'M'" @input="$emit('update:genero', $event.target.value)"/>
              <label for="input__macho">Macho</label>
              <input type="radio" id="input__femea" name="GENERO" value="F" v-bind:checked="genero === 'F'" @input="$emit('update:genero', $event.target.value)"/>
              <label for="input__femea">Fêmea</label>
            </div>
          </div>
          <div class="container__form_col-2">
            <div class="container__idade">
              <p>Idade:</p>
              <input type="radio" id="input__mes" name="IDADE" value="MES" v-bind:checked="tipo_idade === 'MES'" @input="$emit('update:tipo_idade', $event.target.value)"/>
              <label for="input__mes">Meses</label>
              <input type="radio" id="input__anos" name="IDADE" value="ANO" v-bind:checked="tipo_idade === 'ANO'" @input="$emit('update:tipo_idade', $event.target.value)"/>
              <label for="input__anos">Anos</label>
              <input type="number" id="input__idade" placeholder="Escreva a idade" :value="idade" @input="$emit('update:idade', $event.target.value)" />
            </div>
            <div class="container__especie label__input">
              <label for="especie">Espécie:</label>
              <input type="text" name="ESPECIE" id="input__especie" :value="especie" @input="$emit('update:especie', $event.target.value)"/>
            </div>
            <div class="container__tutor label__input">
              <label for="tutor">Tutor:</label>
              <input type="text" name="TUTOR" id="input__tutor" :value="tutor" @input="$emit('update:tutor', $event.target.value)"/>
            </div>
          </div>
        </div>
      </div>
      <button class="form__btn" @click="proximoForm">
        <p>Próximo</p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCadSolicitacaoPaciente',
  props: ['dt_coleta', 'paciente', 'raca', 'genero', 'idade', 'tipo_idade', 'especie', 'tutor', 'proximoForm']
}
</script>

<style scoped>
.wrapper_form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  width: 75%;
  min-width: fit-content;
  height: 70%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--light-color);
  border-radius: 10px;
}

.container__title{
  width: 100%;
  height: 50px;
  padding: 0 50px;
}

.container__title h1{
  color: var(--primary-color);
}

.container__inputs{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: calc(80% - 50px);
  border-radius: 10px;
}

.container__form_col-1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  gap: 30px;
}

.container__form_col-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  gap: 40px;
}

.label__input{
  display: flex;
  flex-direction: column;
  width: 80%;
}

.container__data label{
  width: 200px;
}

.container__genero p,
.container__idade p{
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 10px;
}
.container__genero,
.container__idade{
  width: 80%;
  min-width: 145px;
  gap: 20px;
}

.container__genero input{
  margin-left: 10px;
}
.container__genero label{
  padding-right: 5px;
}

.container__idade input,label{
  margin: 0 0 5px 10px;
}

</style>
