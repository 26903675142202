<template>
  <div class="container-404">
    <div class="text-btn">
      <h1 class="title">
        <p><a>Oops, </a>algo deu errado!!</p>
      </h1>
      <button class="btn" @click="voltar">
        Voltar
      </button>
    </div>
    <div class="container-img">
      <img src="@/assets/images/404Error.png" alt="404 Error">
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
  methods: {
    voltar () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.container-404 {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  background: white;
}

.container-img {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-btn {
  width: 100%;
  height: 30%;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

img {
  height: 475px;
  width: 475px;
}

.title a {
  font-size: 50px;
  color: #3B6CE4;
  font-weight: 700;
}

.title p {
  font-size: 50px;
  color: #80A5FA;
  font-weight: 500;
}

.btn {
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background: #3B6CE4;
  color: #ffff;
  font-size: 20px;
  cursor: pointer;
}

.btn:hover {
  box-shadow: 0 0 15px #80A5FA;
}
</style>
