<template>
  <div class="container__form">
    <div class="form">
      <div class="wrapper_form">
        <div class="container__title">
          <h1 class="tilte">Veterinário:</h1>
        </div>
        <div class="container__inputs">
          <div class="container__veterinario label__input">
            <label for="VETERINARIO">Veterinario:</label>
            <input type="text" name="nome_veterinario" id="input__veterinario" :value="nome_veterinario" @input="$emit('update:nome_veterinario', $event.target.value)"/>
          </div>
          <div class="container__crmv label__input">
            <label for="CRMV">CRMV:</label>
            <input type="text" name="CRMV" id="input__CRMV" :value="crmv" @input="$emit('update:crmv', $event.target.value)"/>
          </div>
          <div class="container__observacao label__input">
            <label for="observacao">Observação:</label>
            <textarea name="OBSERVACAO" id="input__observacao" placeholder="Caso não haja escreva '#'" :value="observacao" @input="$emit('update:observacao', $event.target.value)"></textarea>
          </div>
        </div>
      </div>
      <button :class="`form__btn ${btnDesativado ? 'desativado' : ''}`" type="submit">
        <p>Finalizar</p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCadSolicitacaoVeterinario',
  props: ['nome_veterinario', 'crmv', 'observacao', 'btnDesativado']
}
</script>

<style scoped>
.wrapper_form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  width: 75%;
  min-width: fit-content;
  height: 70%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--light-color);
  border-radius: 10px;
}

.container__title{
  width: 100%;
  height: 50px;
  padding: 0 50px;
}

.container__title h1{
  color: var(--primary-color);
}

.container__inputs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  gap: 30px;
  height: calc(80% - 50px);
  border-radius: 10px;
}

.container__veterinario{
  width: 80%;
  gap: 10px;
  margin-top: 30px;
}
textarea{
  resize: none;
  height: 100px;
}

.label__input{
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 10px;
}

.desativado{
  pointer-events: none;
  background-color: #038b19a4;
}
</style>
