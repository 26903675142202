<template>
  <div class="container__form">
    <div class="container__title">
      <h1>Categorias:</h1>
    </div>
    <div class="form">
      <div class="wrapper_categorias-exame">
        <div class="categoria-exame"
          v-for="categoria in categorias"
          :key="categoria.ID_CATEGORIA"
          @click="attCurrentCategoria($event);exibirExames(categoria.ID_CATEGORIA)"
        >
          <p>{{ categoria.NOME_CATEGORIA }}</p>
        </div>
      </div>
      <div class="wrapper_exames">
        <div class="container__title" id="exames">
        <p>Exames</p>
        </div>
        <div class="exames"
          v-if="exames"
        >
          <div class="exame"
          v-for="exame in exames"
            :key="exame.ID_TIPO_EXAME"
          >
            <input type="checkbox" :name="exame.NOME_TIPO_EXAME" v-bind:checked="tipos_de_exame.includes(exame.ID_TIPO_EXAME)" @click="addOuRemoveTipoExame(exame.ID_TIPO_EXAME)">
            <div class="descricao_exame">
              <p class="titulo">{{ exame.NOME_TIPO_EXAME}}</p>
              <p class="amostra"><b>Amostra: </b> {{ exame.AMOSTRA }}</p>
              <p class="prazo"><b>Prazo: </b> {{ exame.PRAZO }} dias</p>
            </div>
          </div>
        </div>
        <BaseLoading
          v-else
        />
      </div>
      <button class="form__btn" @click="proximoForm">
        <p>Próximo</p>
      </button>
    </div>
  </div>
</template>

<script>
import BaseLoading from '@/components/BaseLoading.vue'

export default {
  name: 'FormCadSolicitacaoExame',
  props: ['categorias', 'exames', 'exibirExames', 'tipos_de_exame', 'addOuRemoveTipoExame', 'proximoForm'],
  components: {
    BaseLoading
  },
  methods: {
    attCurrentCategoria (event) {
      if (this.currentCategoria) this.currentCategoria.classList.remove('current')
      event.target.classList.add('current')
      this.currentCategoria = event.target
    }
  },
  mounted () {
    this.$emit('update:exames', true)
  }
}
</script>

<style scoped>
.container__form {
  flex-direction: column;
  justify-content: flex-start;
}

.container__title {
  color: var(--light-color);
  margin-bottom: 15px;
}

.form {
  height: 80%;
  width: 100%;
  padding: 0 50px;
}

.wrapper_categorias-exame {
  width: 100%;
  height: 20%;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
}

.categoria-exame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  width: 210px;
  min-height: 60px;
  border-radius: 7px;
  font-size: 1.05em;
  font-weight: bold;
  background-color: var(--light-color);
  color: var(--primary-color);
  padding: 5px;
  word-wrap: break-word;
}

.categoria-exame p{
  max-width: 80%;
  pointer-events: none;
}

.categoria-exame.current {
  background-color: var(--primary-color);
  color: var(--light-color);
  border: 2px solid var(--light-color);
}

.wrapper_exames {
  width: 80%;
  min-height: 300px;
  height: 50vh;
  margin: 20px auto;
  overflow-y: auto;
  background-color: var(--light-color);
  border-radius: 10px;
}

.container__title#exames {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 15px #00000020;
  color: #000;
  font-weight: bold;
  padding-left: 15px;
}

.exame {
  width: 100%;
  min-height: 85px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  gap: 15px;
  word-break: break-all;
}

.exame:last-child {
  border: none;
}

.exame .descricao_exame .titulo {
  color: #1f2f55;
  font-weight: bold;
  margin-bottom: 5px;
}

.exame .descricao_exame .amostra,
.exame .descricao_exame .prazo
{
  font-size: 0.9em;
}

.form__btn {
  margin: 0 auto;
}
@media (max-width: 840px) {
  .form {
    padding: 0 25px
  }
  .container__title h1{
    font-size: 25px;
  }
  .wrapper_categorias-exame {
    height: 100px;
    justify-content: center;
  }
  .wrapper_exames {
    height: 45vh;
    min-height: 300px;
  }
}
</style>
