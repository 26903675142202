<template>
  <div class="wrapper__modal" v-if="showModal">
    <div class="modal-notificacao">
      <div class="container__all-notificacao"
        v-if="notificacoes.length > 0"
      >
        <div class="container__mensagem-notificacao"
          v-for="(notificacao, i) in notificacoes"
          :key="i"
        >
          <p class="mensagem__notificacao">
            {{ notificacao.MENSAGEM }}
          </p>
        </div>
      </div>
      <p class="mensagem__notificacao"
        v-else
      >
        Nenhuma Notificação
      </p>
    </div>
  </div>

  <div :class="`container__notificacao ${(novidade) ? 'novidade' : ''}`" @click="showModal = !showModal; novidade = false">
    <div class="notificacao__bell-icon">
      <img src="@/assets/images/bellNotification.svg" alt="Sino" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BaseNotificacao',
  data () {
    return {
      showModal: false,
      novidade: false,
      notificacoes: null
    }
  },
  methods: {
    getNotificacoes () {
      axios
        .get('https://labbvet.boituvet.com.br/private/api/GET/notificacoes.php', {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          if (!data.error) {
            const notificacaoNaoEhNull = this.notificacoes !== null
            const modalFechado = !this.showModal
            const notificacaoDiferenteData = notificacaoNaoEhNull && (JSON.stringify(this.notificacoes) !== JSON.stringify(data))
            this.novidade = this.novidade || (modalFechado && notificacaoDiferenteData)
            const playAudio = notificacaoNaoEhNull && notificacaoDiferenteData
            if (playAudio) {
              const audio = new Audio('/audio/notificacao.mp3')
              audio.play()
            }
            this.notificacoes = data
          }
        })
        .catch((_) => {
          console.log(_)
        })
    }
  },
  mounted () {
    this.getNotificacoes()
    this.intervalNotificacoes = setInterval(() => {
      this.getNotificacoes()
    }, 5000)
  }
}
</script>

<style scoped>
.container__notificacao {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 20px 20px 0;
  max-width: 100%;
  height: auto;
  cursor: pointer;
  z-index: 55555;
}

.notificacao__bell-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #2f467f;
}

.notificacao__bell-icon img {
  width: 30px;
  height: 30px;
}

.wrapper__modal {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 33333;
  background: #00000007;
  backdrop-filter: blur(2px);
}

/* Modal notificação */
.modal-notificacao {
  max-width: 415px;
  min-width: 315px;
  width: 50%;
  height: 465px;
  padding: 15px;
  background-color: var(--primary-color);
  border-radius: 10px;
  border-left: 1px solid var(--primary-color);
  position: absolute;
  bottom: 60px;
  right: 60px;
}

.container__all-notificacao {
  max-width: 100%;
  max-height: 85%;
  overflow-y: scroll;
  border-radius: 10px;
}

.container__mensagem-notificacao {
  width: 100%;
  height: 50px;
  background-color: var(--light-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container__mensagem-notificacao p {
  font-size: 15px;
  padding: 5px;
  color: rgb(0, 0, 0);
}

.mensagem__notificacao{
  text-align: center;
  font-size: 20px;
  color: var(--light-color);
  margin-top: 10px;
}

@media all and (min-width: 850px) {
  /* Notificação */
  /* Botão Notificação */

  .container__notificacao {
    display: flex;
    border-radius: 50%;
    background-color: #2f467f;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0 20px 20px 0;
    max-width: 100%;
    height: auto;
  }

  .notificacao__bell-icon {
    height: 60px;
    width: 60px;
  }

  .notificacao__bell-icon img {
    width: 30px;
    height: 30px;
  }

  .container__notificacao.novidade::before {
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #00ff22;
  }

  /* Modal notificação */

  .container__modal-notificacao {
    display: none;
    width: 30%;
    height: 300px;
    background-color: var(--primary-color);
    border-radius: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0 20px 100px 0;
  }

  .container__all-notificacao {
    max-width: 100%;
    max-height: 85%;
    overflow-y: scroll;
    border-radius: 10px;
  }

  .container__mensagem-notificacao {
    width: 100%;
    height: 50px;
    background-color: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .container__mensagem-notificacao p {
    font-size: 15px;
    padding: 5px;
    color: rgb(0, 0, 0);
  }
}
</style>
