<template>
  <div class="wrapper_modal" >
    <div class="modal">
      <div class="modal_header">
        <div class="modal_header-span">
          <span>Excluir Solicitações</span>
        </div>
      </div>
      <div class="modal_body">
        <div class="container_form">
          <div class="error">
            <p v-if="callback.dataFimMaiorDataInicio">Data Inicio deve ser menor ou igual ao de fim</p>
          </div>
          <label for="INICIO">Data de Incio:</label>
          <input type="date" id="data_inicio" name="INICIO" :value="dataInicio" @input="$emit('update:dataInicio', $event.target.value)">
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('dataInicio')">Campo vazio</p>
            <p v-if="callback.invalido && callback.invalido.includes('dataInicio')">Data inválida</p>
          </div>
          <label for="FIM">Data de Fim:</label>
          <input type="date" id="data_fim" name="FIM" :value="dataFim" @input="$emit('update:dataFim', $event.target.value)">
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('dataFim')">Campo vazio</p>
            <p v-if="callback.invalido && callback.invalido.includes('dataFim')">Data inválida</p>
          </div>
        </div>
        <div class="container__form-btn">
          <button @click="$emit('excluirSolicitacoes')" class="form__btn bnt-confirmar">Confirmar</button>
          <button @click="$emit('close')" class="form__btn btn-cancelar" >Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalExcluirSolicitacoes',
  props: ['dataInicio', 'dataFim', 'callback']
}
</script>

<style scoped>
/* Modal edição */
.error {
  height: 16px;
  color: #e04545;
  font-weight: bold;
  margin: 10px 0;
}
.wrapper_modal {
  width: 100%;
  height: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #00000095;
  backdrop-filter: blur(5px);
}

.modal {
  width: 70%;
  z-index: 9999;
  min-height: 400px;
  background-color: var(--light-color);
  border-radius: 10px;
  border: 1px solid var(--primary-color);
}

.modal_header{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.modal_header-span{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--primary-light-color);
  text-transform: uppercase;
}

.modal_header__close{
  font-size: 20px;
  cursor: pointer;
}

.modal_header__close{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  width: 50%;
  margin-right: 15px;
}
.modal_body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 20px);
  width: 100%;
  gap: 20px;
}

.modal_body p{
  font-weight: bold;
}
.container_form{
  width: 65%;
  height: 100%;
  margin-top: 20px;
}

.container_form label,input{
  width: 100%;
  height: 20px;
}
.container_form label {
  margin-bottom: 20px;
}
.container__form-btn{
  min-height: 100px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.form__btn {
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  height: 50px;
}

.bnt-confirmar:hover{
  box-shadow: 0 0 20px #3fd362, 0 0 5px #3fd362c0 inset;
}
.btn-cancelar{
  background-color: #ff0000;
}

.btn-cancelar:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

@media (max-width:500px){
  .modal_body{
    margin-top: 20px;
  }

  .container__form-btn{
    gap: 10px;
  }

}
</style>
