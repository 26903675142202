<template>
  <div class="wrapper_modal" >
    <div class="modal">
      <div class="modal_header">
        <div class="modal_header-span">
          <span>Cadastre uma nova categoria</span>
        </div>
      </div>
      <div class="modal_body">
        <div class="container_form">
          <label class="label" for="NOME_EXAME">Nome da categoria:</label>
          <input type="text" :value="nomeCategoria" id="nome-categoria" name="nomeCategoria"
          @input="$emit('update:nomeCategoria', $event.target.value)"
          >
          <p class="error">{{ callback['error'] ? callback['error'] : ""  }}</p>
        </div>
        <div class="container__button">
          <button @click="$emit('cadastrarCategoria')" class="form__btn btn-success" >Confirmar</button>
          <button @click="$emit('close')" class="form__btn btn-danger" >Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: ['ModalCadCategoria'],
  props: ['nomeCategoria', 'callback']
}
</script>

<style scoped>
.error {
  height: 16px;
  color: #e04545;
  font-weight: bold;
  margin-top: 5px;
}

.wrapper_modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #00000095;
  backdrop-filter: blur(5px);
}

.modal {
  min-width: 500px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
}

.modal_header{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
}
.modal_header-span{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--primary-light-color);
  text-transform: uppercase;
}

.modal_header__close{
  font-size: 20px;
  cursor: pointer;
}

.modal_header__close{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  width: 50%;
  margin-right: 15px;
}
.modal_body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 20px);
  width: 100%;
  gap: 20px;
}

.modal_body p{
  font-weight: bold;
}

.container_form{
  width: 65%;
}

.container_form label,input{
  width: 100%;
  height: 20px;
}

.container__button{
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn{
  width: 80px;
  height: 50px;
  border-radius: 10px;
  border:none;
  font-size: 20px;
  color: var(--light-color);
}

.btn-success{
  background-color: #3fd362;
}
.btn-success:hover{
  box-shadow: 0 0 10px #3fd362, 0 0 5px #3fd362c0 inset;
}

.btn-danger{
  background-color: #f13131;
}
.btn-danger:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}
</style>
