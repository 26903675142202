<template>
  <router-view v-cloak></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
:root {
  --primary-color: #2f467f;
  --secondary-color: #d70000;
  --light-color: #fff;
  --primary-light-color: #3b59a5;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif;
}

[v-cloak] {
  display: none;
}

#app {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: gray;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(204, 204, 204);
  border-radius: 15px;
}
a,
btn,
router-link{
  cursor: pointer;
}

.voltar img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

</style>
