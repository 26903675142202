<template>
  <div class="container-login">
    <!-- Header -->
    <header class="container__header">
      <div class="header-logo">
        <a href="httpss://boituvet.com.br"><img src="@/assets/images/logo.png" alt="Boituvet"></a>
      </div>
    </header>
    <!-- App -->
    <main class="container__main">
      <form action="./functions/login.php" method="post" class="form" @submit.prevent="submit()">
        <div class="container__form-title">
          <h1 class="form-title">Login</h1>
        </div>
        <div class="container__form-input">
          <div class="container__form-user">
            <label for="USER_LOGIN" class="label label__user">Login:</label>
            <input type="text" id="input_user" name="LOGIN_USER" v-model="login">
            <p class="error">{{ (callback.vazio && callback.vazio.includes('LOGIN_USER')) ? "Campo vazio" : "" }}</p>
          </div>
          <div class="container__form-password">
            <label for="USER_PASSWORD" class="label label_password">Senha:</label>
            <input type="password" id="input_password" name="LOGIN_PASSWORD" v-model="password">
            <p class="error">{{ (callback.vazio && callback.vazio.includes('LOGIN_PASSWORD')) ? "Campo vazio" : "" }} </p>
          </div>
        </div>
        <div class="container__form-button">
          <button type="submit" class="form__btn">
            <p>Confirmar</p>
          </button>
        </div>
      </form>
      <p class="error">{{  callback.userNotFound ? "Login e/ou senha incorretos" : ""  }}</p>
    </main>
    <!-- footer -->
    <footer class="container__footer">
      <div class="footer__direitos-boituvet">
        <p>Boituvet Clínica Veterinária - Todos os direitos reservado</p>
      </div>
      <div class="footer__social">
        <div class="social__instagram">
        <a href="httpss://www.instagram.com/clinicaveterinaria_boituvet/" class="instagram">
          <img class="logo" src="@/assets/images/logoInstagram.svg" alt="Facebook">
        </a>
        </div>
        <div class="social__facebook">
          <a href="httpss://www.facebook.com/vetnasuacasapf/?locale=pt_BR" class="facebook">
            <img class="logo" src="@/assets/images/logoFacebook.svg" alt="Facebook">
          </a>
        </div>
      </div>
      <div class="footer_credito">
        <p>Desenvolvido por <a href="httpss://github.com/Lucas-r-Nogueira">Lucas Nogueira</a>  e  <a href="httpss://github.com/sousa-p">Pedro Sousa</a> </p>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginView',
  data () {
    return {
      login: '',
      password: '',
      callback: {}
    }
  },
  mounted () {
    this.ifLogged()
  },
  methods: {
    ifLogged () {
      axios
        .get('https://labbvet.boituvet.com.br/private/api/GET/user.php', {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          console.log(response)
          return response.data
        })
        .then((user) => {
          if (!user.userNotFound) this.$router.replace(`/${user.TIPO_USER}`)
        })
    },
    submit () {
      const formData = new FormData()
      formData.append('LOGIN_USER', this.login)
      formData.append('LOGIN_PASSWORD', this.password)
      axios.post('https://labbvet.boituvet.com.br/private/api/PUT/login.php', formData, {
        withCredentials: true
      })
        .then((response) => {
          console.log(response)
          return response.data
        })
        .then((data) => {
          this.callback = data
          if (this.callback.jwt) {
            window.localStorage.setItem('token', this.callback.jwt)
            this.ifLogged()
          }
        })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.container-login {
  width: 100vw;
  height: 100vh;
}
/* header */
.container__header {
  display: flex;
  background-color: var(--light-color);
  width: 100%;
  height: 60px;
  box-shadow: 0 0 15px #00000070;
}

.header-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
}

.header-logo img {
  width: 90px;
  height: 40px;
  margin: 30px;
}

.header-back {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
  padding-right: 40px;
}

.header-back ion-icon {
  width: 36px;
  height: 30px;
  color: var(--secondary-color);
}

/* Main */
.container__main {
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Form */
.form {
  margin-top: 20px;
  width: 45%;
  max-width: 500px;
  min-width: 330px;
  height: 500px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  background: var(--primary-color);
  border-radius: 10px;
}

.form-title {
  color: white;
  text-align: center;
}

/* input */
.container__form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 60%;
}

.container__form-user {
  margin-bottom: 15px;
}

.container__form-user,
.container__form-password {
  width: 100%;
  height: 70px;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--light-color);
  font-size: var(--medium-size);
  font-weight: bold;
  padding: 0 0 10px 0;
}

.form input {
  width: 100%;
  height: 25px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  color: white;
  background-color: transparent;
}

.container__form-button {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  background-color: var(--light-color);
  border: none;
  border-radius: 10px;
  box-shadow: -4px 0px 57px -25px #000;
  margin-bottom: 25px;
}

.form__btn p {
  font-weight: bold;
  color: var(--primary-color);
}

/* footer */
.container__footer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--primary-color);
  font-size: 15px;
}

.footer__direitos-boituvet,
.footer__social,
.footer_credito {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3333%;
}

.footer__direitos-boituvet,
.footer_credito p {
  color: var(--light-color);
  font-weight: bold;
  margin-left: 10px;
}

.social__instagram,
.social__facebook {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 40px;
  color: var(--light-color);
}

.footer__social a ion-icon {
  width: 20px;
  height: 20px;
  color: var(--light-color);
  transition: 0.5s;
}

.footer__social .instagram :hover {
  color: #8a3ab9;
  transition: 0.5s;
}

.footer__social .facebook :hover {
  color: #2066fd;
  transition: 0.5s;
}

.footer_credito a {
  color: rgba(0, 255, 255, 0.596);
  font-style: none;
  text-decoration: none;
}

@media (max-width: 775px) {
  .container__main {
    height: calc(100vh - 210px);
    min-height: 516px;
  }

  .container__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 150px;
  }

  .footer__direitos-boituvet,
  .footer__social,
  .footer_credito {
    margin-bottom: 10px;
    width: 90%;
  }

  .container__footer p {
    text-align: center;
  }
}

.error {
  height: 16px;
  color: #e04545;
  font-weight: bold;
  margin-top: 5px;
}

@media (max-width: 400px) {
  .form p {
    font-size: 13px;
  }

  .label {
    font-size: 13px;
  }
}
</style>
