<template>
  <div class="loading"></div>
</template>

<script>
export default {
  name: 'BaseLoading'
}
</script>
<style>
.loading {
  position: relative;
  top: calc(50% - 111px);
  margin: 0 auto;
  animation: carregar 1s infinite;
  width: 74px;
  height: 74px;
  border: 5px solid #aaaaaa;
  border-top: 5px solid #2f467f;
  border-radius: 50%;
  z-index: 99999;
}

.loading.center {
  position: absolute;
  top: calc(50% - 74px);
  left: calc(50% - 37px);
}

@keyframes carregar{
  to{
    transform: rotate(1turn);
  }
}
</style>
