<template>
  <div class="wrapper_modal" >
    <div class="modal">
      <div class="modal_header">
        <div class="modal_header-span">
          <span>Adição de Exames</span>
        </div>
      </div>
      <div class="modal_body">
        <div class="container_form">
          <label class="label" for="NOME_EXAME">Nome do Exame:</label>
          <input type="text" :value="exame.NOME_TIPO_EXAME" id="input__nome-exame" name="NOME_EXAME"
            @input="$emit('update:nome_tipo_exame', $event.target.value)"
          >
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('nome_tipo_exame')">Campo vazio:</p>
          </div>

          <label for="CUSTO">Custo:</label>
          <input type="number" :value="exame.CUSTO" min="0" max="9999" id="input__custo" name="CUSTO"
            @input="$emit('update:custo', $event.target.value)"
          >
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('custo')">Campo vazio:</p>
            <p v-else-if="callback.menorZero && callback.menorZero.includes('custo')">Campo deve ser maior que zero</p>
          </div>

          <label for="PRECO">Preço Venda:</label>
          <input type="number" :value="exame.PRECO" min="0" max="9999" id="input__preco" name="PRECO"
            @input="$emit('update:preco', $event.target.value)"
          >
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('preco')">Campo vazio:</p>
            <p v-else-if="callback.menorZero && callback.menorZero.includes('preco')">Campo deve ser maior que zero</p>

          </div>

          <label for="AMOSTRA">Amostra:</label>
          <input type="text" :value="exame.AMOSTRA" id="input__amostra" name="AMOSTRA"
            @input="$emit('update:amostra', $event.target.value)"
          >
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('amostra')">Campo vazio:</p>
          </div>

          <label for="PRAZO">Prazo(dias):</label>
          <input type="number" min="0" :value="exame.PRAZO" id="input__prazo" name="PRAZO"
            @input="$emit('update:prazo', $event.target.value)"
          >
          <div class="error">
            <p v-if="callback.vazio && callback.vazio.includes('prazo')">Campo vazio:</p>
            <p v-else-if="callback.menorZero && callback.menorZero.includes('prazo')">Campo deve ser maior que zero</p>
            <p v-else-if="callback.nao_eh_inteiro && callback.nao_eh_inteiro.includes('prazo')">Campo deve ser inteiro</p>
          </div>
        </div>
        <div class="container__form-btn">
          <button @click="$emit('cadastrar')" class="form__btn bnt-confirmar" >Confirmar</button>
          <button @click="$emit('close')" class="form__btn btn-cancelar" >Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalCadExame',
  props: ['exame', 'callback']
}
</script>

<style scoped>
/* Modal edição */
.error {
  height: 16px;
  color: #e04545;
  font-weight: bold;
  margin: 10px 0;
}
.wrapper_modal {
  width: 100%;
  height: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #00000095;
  backdrop-filter: blur(5px);
}

.modal {
  width: 50%;
  min-width: 450px;
  z-index: 9999;
  min-height: 400px;
  background-color: var(--light-color);
  border-radius: 10px;
  border: 1px solid var(--primary-color);
}

.modal_header{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_header-span{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--primary-light-color);
  text-transform: uppercase;
}

.modal_header__close{
  font-size: 20px;
  cursor: pointer;
}

.modal_header__close{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  width: 50%;
  margin-right: 15px;
}
.modal_body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 20px);
  width: 100%;
  gap: 20px;
}

.modal_body p{
  font-weight: bold;
}
.container_form{
  width: 65%;
  height: 100%;
}

.container_form label,input{
  width: 100%;
  height: 20px;
}
.container_form label {
  margin-bottom: 20px;
}
.container__form-btn{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.form__btn {
  width: 100px;
  height: 50px;
}

.bnt-confirmar:hover{
  box-shadow: 0 0 20px #3fd362, 0 0 5px #3fd362c0 inset;
}
.btn-cancelar{
  background-color: #ff0000;
}

.btn-cancelar:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

</style>
