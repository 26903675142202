<template>
  <div :class="`wrapper_modal ${tipoModal}`" >
    <div class="modal">
      <div class="modal_header">
        <div class="modal_header-span">
        <span v-if="tipoModal === 'success'" class="span-sucess">SOLICITAÇÃO CADASTRADA COM SUCESSO</span>
        <span v-else class="span-false">SOLICITAÇÃO NÃO REALIZADA</span>
        </div>
        <img class="modal_header__close" src="@/assets/images/closeIcon.svg" alt="X"
          @click="$emit('close')"
        >
      </div>
      <div class="modal_body">
        <p
          v-for="(mensagem,i) in mensagens"
          :key="i"
        >
          {{ mensagem }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: ['tipoModal', 'mensagens']
}
</script>

<style scoped>
.wrapper_modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  background-color: #00000095;
  backdrop-filter: blur(5px);
}

.modal {
  width: 40%;
  height: 500px;
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
}

.modal_header{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}
.modal_header-span{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 150px;
  padding-top: 20px;
  margin-left: 10px;
  font-weight: bold;
  color: var(--primary-light-color);
  text-transform: uppercase;
}

.span-sucess{
  color:#3fd362;
}
.span-false{
  color: #ff0000;
}

.modal_header__close{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  margin-right: 15px;
}
.modal_body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: 90%;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
}

.modal_body p{
  color: var(--primary-light-color);
  font-weight: bold;
  text-align: center;
}
@media (max-width:838px){
  .modal {
    width: 80%;
  }
}
</style>
