<template>
  <div>
    <ModalSolicitacao
      :solicitacao="solicitacao"
      :examesEmSolicitacao="examesEmSolicitacao"
      @close="showModalSolicitacao = false"
      v-if="showModalSolicitacao && examesEmSolicitacao && solicitacao"
    />
    <!--Modal Filtros Solicitação-->
    <ModalFiltrosSolicitacoes
      v-if="formFiltrosSolicitacoes.show"
      :limit="limit"
      @addFiltros="getSolicitacoes(); formFiltrosSolicitacoes.show = false"
      @update:limit="limit = $event; salvarFiltros(); "
      @close="formFiltrosSolicitacoes.show = false"
    />

    <header class="container__header">
      <div class="header__logo">
        <img src="@/assets/images/logo.png" alt="Boituvet" />
      </div>

      <div class="header__titles">
        <li @click="formFiltrosSolicitacoes.show = true">Filtros</li>
        <a @click="logout" id="logout"><img src="@/assets/images/logout.svg" alt="Logout"></a>
      </div>
    </header>
    <main>
      <!-- Table Solicitações -->
      <TabelaSolicitacoes
        :solicitacoes="solicitacoes"
        @update:solicitacao="solicitacao = solicitacoes[$event]; getExamesEmSolicitacao()"
        @abrir="showModalSolicitacao = true"
        @alterarStatus="alterarStatus($event)"
        v-if="solicitacoes"
      />
      <BaseLoading class="center"
        v-else
      />
      <!-- Noficações -->
      <BaseNotificacao/>
    </main>
  </div>
</template>

<script>
import TabelaSolicitacoes from './components/TabelaSolicitacoes.vue'
import ModalSolicitacao from './components/ModalSolicitacao.vue'
import BaseLoading from '@/components/BaseLoading.vue'
import BaseNotificacao from '@/components/BaseNotificacao.vue'
import ModalFiltrosSolicitacoes from '@/components/FiltrosModal.vue'
import axios from 'axios'

export default {
  name: 'DashboardVetView',
  components: {
    TabelaSolicitacoes,
    ModalSolicitacao,
    BaseLoading,
    BaseNotificacao,
    ModalFiltrosSolicitacoes
  },
  data () {
    return {
      limit: window.localStorage.getItem('limit') ?? 20,
      user: {},
      solicitacoes: null,
      solicitacao: null,
      examesEmSolicitacao: null,
      showModalSolicitacao: false,
      toggle: true,
      formFiltrosSolicitacoes: {
        show: false
      }
    }
  },
  mounted () {
    this.getSolicitacoes()
    setInterval(() => {
      this.getSolicitacoes()
    }, 5000)
  },
  methods: {
    salvarFiltros () {
      if (isNaN(this.limit) || this.limit < 0) {
        this.limit = 0
      } else if (this.limit > 100) {
        this.limit = 100
      }
      window.localStorage.setItem('limit', this.limit)
    },
    logout () {
      axios
        .get('https://labbvet.boituvet.com.br/private/api/PUT/logout.php', {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then(() => {
          window.localStorage.clear()
          location.reload()
        })
        .catch((_) => {
          console.log(_)
        })
    },
    getSolicitacoes () {
      const params = (this.limit != null && this.limit > 0) ? `?limit=${this.limit}` : ''

      axios
        .get(`https://labbvet.boituvet.com.br/private/api/GET/solicitacoes.php${params}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.solicitacoes = data
        })
        .catch((_) => {
          console.log(_)
        })
    },
    getExamesEmSolicitacao () {
      this.examesEmSolicitacao = null
      axios
        .get(`https://labbvet.boituvet.com.br/private/api/GET/examesEmSolicitacao.php?id_solicitacao=${this.solicitacao.ID_SOLICITACAO}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
        })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.examesEmSolicitacao = data
        })
        .catch((_) => {
          console.log(_)
        })
    },
    alterarStatus (data) {
      const formData = new FormData()
      formData.append('idSolicitacao', data.idSolicitacao)
      formData.append('novoStatus', data.novoStatus)
      axios.post('https://labbvet.boituvet.com.br/private/api/PUT/statusSolicitacao.php', formData, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${window.localStorage.getItem('token')}` }
      })
        .then((response) => {
          return response.data
        })
        .then((data) => {
          this.callback = data
        })
        .then(() => {
          if (this.callback.success) {
            this.getSolicitacoes()
          }
        })
        .catch((_) => {
          console.log(_)
        })
    }
  }
}
</script>

<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif;
}

body {
  width: 100%;
  height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(95vh - 60px);
  margin: 5vh 0 0 0;
}

/* Header */
.container__header {
  display: flex;
  justify-content: space-between;
  background-color: var(--light-color);
  width: 100%;
  height: 60px;
  padding: 0 30px;
  box-shadow: 0 0 15px #00000070;
}

.header__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.header__logo img {
  width: 90px;
  height: 40px;
}

.header__titles {
  width: 25%;
  display: flex;
  align-items: center;
}

.header__titles li {
  list-style: none;
  font-size: 20px;
  cursor: pointer;
}

.header__titles li a {
  text-decoration: none;
  color: #000;
  transition: 0.5s;
}

.header__titles li a:hover {
  transition: 0.5s;
  color: var(--primary-color);
}

#logout {
  margin-left: auto;
}
</style>
