<template>
  <div class="wrapper_modal" >
    <div class="modal">
      <div class="modal_header">
        <div class="modal_header-span">
          <span>{{ text }}</span>
        </div>
      </div>
      <div class="modal_body">
        <div class="container__button">
          <button @click="$emit('excluir')" class="form__btn btn-success" >Confirmar</button>
          <button @click="$emit('close')" class="form__btn btn-danger" >Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmacao',
  props: ['text']
}
</script>

<style scoped>
.wrapper_modal {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #00000095;
  backdrop-filter: blur(5px);
}

.modal {
  margin: 10px;
  width: 50%;
  height: 250px;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0 10px 0;
}

.modal_header{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  min-height: 50px;
}
.modal_header-span{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 10px 0 10px;
  font-weight: bold;
  color: var(--primary-light-color);
  text-transform: uppercase;
}
.modal_body{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  height: calc(100% - 80px);
  width: 100%;
  gap: 20px;
}

.modal_body p{
  font-weight: bold;
}

.container__button{
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.btn-success{
  background-color: #3fd362;
}
.btn-success:hover{
  box-shadow: 0 0 10px #3fd362, 0 0 5px #3fd362c0 inset;
}

.btn-danger{
  background-color: #f13131;
}
.btn-danger:hover{
  box-shadow: 0 0 10px #f13131, 0 0 5px #f13131c0 inset;
}

@media (max-width:500px){

    .container__button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      font-size: 10px;
      margin-top: 20px;
    }
    .container__button button{
      width: 70%;
    }

}
</style>
